/*  BaMgNbYVuRm69e  nbvwtguu import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();*/

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught in ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>La connexion est intérrompue.</h1>;
    }

    return this.props.children;
  }
}

// Sélectionnez l'élément racine de votre application
const container = document.getElementById('root');

// Créez un root en utilisant React 18
const root = createRoot(container);

// Utilisez le root pour rendre votre application
root.render(
  <React.StrictMode>
    <BrowserRouter>
    {/* <ErrorBoundary> */}
      <App />
    {/* </ErrorBoundary> */}
    </BrowserRouter>
  </React.StrictMode>
);

