import React from "react";
  const entrepriseType = localStorage.getItem("entrepriseType");

const Navbar = ({ title, toggleSidebar, navigate, entreprises, handleChange, localS }) => (
  <nav className="navbar navbar-expand navbar-light bg-light shadow-sm">
    <button className="btn btn-link text-dark" onClick={toggleSidebar}>
      <i className="fa fa-bars"></i>
    </button>
    <a className="navbar-brand ms-3">{title}</a>
    <ul className="navbar-nav ms-auto">
    {entrepriseType === '1' && <select
            value={JSON.stringify(localS)}
            onChange={async (e) => {

              if(e.target.value !== ""){
                const kk = JSON.parse(e.target.value)
                await handleChange(kk)

              }
               /*localStorage.setItem('entrepriseId', (e.target.value[0]).toString());
               localStorage.setItem('entrepriseName', e.target.value[1]);
               localStorage.setItem('entrepriseType', (e.target.value[2]).toString());*/
            // setTimeout(()=>window.location.reload(),1000);

            }}
            className="nav-item"
          >
            <option value="">Select Entreprise</option>
            {entreprises.map(entreprise => {
              let koz = [];
                koz.push(entreprise.id)
                koz.push(entreprise.name)
                koz.push(entreprise.type)
                const zz = JSON.stringify(koz)
              return <option key={entreprise.id} value={zz}>{entreprise.name}</option>
            })}
          </select>}
      <li className="nav-item">
        <a href="#" className="nav-link" onClick={() => {
           localStorage.setItem('entrepriseId', '');
          localStorage.setItem('entrepriseName', '');
          localStorage.setItem('entrepriseType', '');
          navigate('/login');
        }}>
          <i className="fa fa-power-off"></i> Déconnexion
        </a>
      </li>
    </ul>
  </nav>
);

export default Navbar;
