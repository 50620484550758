import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { api } from '../config/constant.js';
import DataTable from 'react-data-table-component';
import * as CryptoJS from 'crypto-js';

export default function Driver() {
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [entreprises, setEntreprises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDriver, setCurrentDriver] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [searchText, setSearchText] = useState("");
  const entrepriseType = localStorage.getItem("entrepriseType");

  function  encryptUsingAES256(code) {
    let _key = CryptoJS.enc.Utf8.parse("0123456789123456");
    let _iv = CryptoJS.enc.Utf8.parse("0123456789123456");
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(code), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    let encrypted1 = encrypted.toString();
    return  encrypted1
  }

  function decryptUsingAES256(code) {
    let _key = CryptoJS.enc.Utf8.parse("0123456789123456");
    let _iv = CryptoJS.enc.Utf8.parse("0123456789123456");

    let decrypted = CryptoJS.AES.decrypt(
      code, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);

      return decrypted.slice(1,decrypted.length - 1)
  }

  useEffect(() => {
    fetchEntreprises();
    loadDrivers();
  }, []);

  const loadDrivers = () => {
    setIsLoading(true);
    setDrivers([])
    setFilteredDrivers([])
    const entrepriseId = localStorage.getItem("entrepriseId");
    console.log('entrepriseId', entrepriseId)
    axios.get(api + "/entreprises/getAll")
      .then(async response =>{

        const bob = await response.data.data.filter((ite)=>{
          return ite.id == entrepriseId;
        })
        if(entrepriseType === '1'){
          setDrivers(response.data.data)
          setFilteredDrivers(response.data.data)

          setIsLoading(false)
        }else{
          setDrivers(bob)
          setFilteredDrivers(bob)
                    setIsLoading(false)

        }

      });
  /*  const url = entrepriseType === "1"
      ? `${api}/entreprises/getAll`
      : `${api}/zeze1/`+12;

    const options = entrepriseType === "1"
      ? { method: "GET" }
      : {
          method: "GET"
        };

    fetch(url, options)
      .then(response => response.json())
      .then(data => {
        console.log('data',data)
        if(entrepriseType === "1"){
          setDrivers(data.data);
          setFilteredDrivers(data.data);
        }else{
          setDrivers([data.data]);
          setFilteredDrivers([data.data]);
        }

        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching drivers:", error);
        setIsLoading(false);
      });*/
  };

  const fetchEntreprises = () => {
    axios.get(api + "/entreprises/getAll")
      .then(response => setEntreprises(response.data.data));
  };
const handleSearch = (e) => {
  const searchValue = e.target.value;
  setSearchText(searchValue);
  const filteredData = drivers.filter((driver) => {
    // Vérifie si chaque champ est défini avant d'appeler `toLowerCase`
    const firstName = driver.name ? driver.name.toLowerCase() : "";
    const lastName = driver.email ? driver.email.toLowerCase() : "";
    const immatricule = driver.type === '1' ? 'admin':'entreprise' ;
    const phone = driver.adress ? driver.adress.toLowerCase() : "";
    const de = driver.decalage ? driver.decalage.toLowerCase() : "";

    return (
      firstName.includes(searchValue.toLowerCase()) ||
      lastName.includes(searchValue.toLowerCase()) ||
      immatricule.includes(searchValue.toLowerCase()) ||
      de.includes(searchValue.toLowerCase()) ||
      phone.includes(searchValue.toLowerCase())
    );
  });
  setFilteredDrivers(filteredData);
};

function validerEmail(email) {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
}
  const handleAddOrUpdateDriver = () => {

    if (
      !currentDriver.name ||
      !currentDriver.email ||
      !currentDriver.password ||
      //!currentDriver.adress ||
      !currentDriver.decalage ||
      !currentDriver.type
    ) {
      alert("Veuillez remplir tous les champs.");
      return;
    }
    if(!validerEmail(currentDriver.email)){
            alert("Veuillez ajouter un email valide.");
        return;
    }
    const newpass = encryptUsingAES256(currentDriver.password)
    const newcurrentDriver = ({...currentDriver, password: newpass})

    if(actionType === 'add'){
      delete currentDriver.id;
    }else{
     //  delete currentDriver.decalage;
      delete currentDriver.logo;
      delete currentDriver.objetEmail;
    }

    setIsLoading(true);
    const url = actionType === "add"
      ? api + "/entreprises/add"
      : api + "/entreprises/update";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(currentDriver)
    })
    .then(response => response.json())
    .then(() => {
      loadDrivers();
      resetForm();
    })
    .catch(error => {
      console.error("Error saving :", error);
      setIsLoading(false);
    });
  };

const handleDeleteDriver = (id) => {
  const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");

  if (confirmed) {
    setIsLoading(true);
    fetch(api + '/entreprises/delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id })
    })
    .then(response => response.json())
    .then(() => {
      loadDrivers();
    })
    .catch(error => {
      console.error("Error deleting :", error);
      setIsLoading(false);
    });
  }
};


  const resetForm = () => {
    setShowModal(false);
    setCurrentDriver({});
    setIsLoading(false);
  };

  const openModal = (driver = {}) => {
    setActionType(driver.id ? "update" : "add");
    if(driver.id){
          const bob = decryptUsingAES256(driver.password);
          setCurrentDriver({...driver, password: bob});
    }
    setShowModal(true);
  };

  const columns = [
    { name: "ID", selector: row => row.id, sortable: true },
    { name: "Nom", selector: row => row.name, sortable: true },
    { name: "Email", selector: row => row.email, sortable: true },
    { name: "Mot de passe", selector: row => decryptUsingAES256(row.password), sortable: true },
    { name: "Type", selector: row => row.type === '1' ? 'Admin' : 'Entreprise' , sortable: true },
    { name: "Adresse", selector: row => row.adress, sortable: true },
    { name: "Decalage", selector: row => row.decalage, sortable: true },
    {
      name: "Actions",
      cell: row => (
        <>
          <Button onClick={() => openModal(row)} variant="warning" size="sm"><i className="fa fa-edit"></i></Button>
          <Button onClick={() => handleDeleteDriver(row.id)} variant="danger" size="sm" className="ms-2"><i className="fa fa-trash"></i></Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div className="driver-page">
     { entrepriseType === '1' && <Button onClick={() => openModal()} variant="primary" className="mb-3">Ajout</Button>}

      <input
        type="text"
        placeholder="Rechercher..."
        value={searchText}
        onChange={handleSearch}
        className="form-control mb-3"
      />

      <div className="data-table">
        {isLoading ? (
          <ClipLoader color={"#123abc"} loading={isLoading} size={50} />
        ) : (
          <DataTable
            columns={columns}
            data={filteredDrivers}  // Utilisation des données filtrées
            pagination
          />
        )}
      </div>

      <Modal show={showModal} onHide={resetForm}>
        <Modal.Header closeButton>
          <Modal.Title>{actionType === "add" ? "Ajout " : "Modifier"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <input
            type="text"
            placeholder="Nom"
            value={currentDriver.name || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, name: e.target.value })}
            className="form-control mb-3"
          />
          <input
            type="email"
            placeholder="Email"
            value={currentDriver.email || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, email: e.target.value })}
            className="form-control mb-3"
          />
        <input
            type="text"
            placeholder="Adresse"
            value={currentDriver.adress || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, adress: e.target.value })}
            className="form-control mb-3"
          />
          <input
            type="text"
            placeholder="Decalage"
            value={currentDriver.decalage || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, decalage: e.target.value })}
            className="form-control mb-3"
          />
          <input
            type="text"
            placeholder="Mot de passe"
            value={currentDriver.password || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, password: e.target.value })}
            className="form-control mb-3"
          />
          {entrepriseType === "1" && <select
            value={currentDriver.type || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, type: e.target.value })}
            className="form-control"
          >
            <option value="">Type</option>
            <option value="1">Admin</option>
            <option value="0">Entreprise</option>

          </select>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetForm}>Close</Button>
          <Button variant="primary" onClick={handleAddOrUpdateDriver}>
            {actionType === "add" ? "Ajouter" : "Modifier"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
