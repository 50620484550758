import React from "react";

const Sidebar = ({ toPage, isOpen, navigate }) => (

  <aside  className={`sidebar bg-dark text-light ${isOpen ? "open" : "closed"}`}>
    <div  onClick={() => toPage("home")} className="brand p-3 d-flex align-items-center">
      <img src="/poubelle.jpg" alt="Logo" style={{ width: 30 }} />
      {isOpen && <span  style={{marginLeft:'10px'}} >App Collect</span>}
    </div>
    <nav className="mt-4">
      <ul className="nav flex-column">
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("chauffeur")}>
            <i className="fa fa-user-circle"></i> {isOpen && "Chauffeurs"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("ripeur")}>
            <i className="fa fa-user-circle"></i> {isOpen && "Ripeurs"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("circuit")}>
            <i className="fa fa-road"></i> {isOpen && "Circuits"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("bom")}>
            <i className="fa fa-car"></i> {isOpen && "Voitures"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("anomalie")}>
            <i className="fa fa-bolt"></i> {isOpen && "Liste Anomalie"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapport anomalies")}>
            <i className="fa fa-bolt"></i> {isOpen && "Rapport anomalies"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapport journalier")}>
            <i className="fa fa-list"></i> {isOpen && "Rapport journalier"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapport pointage")}>
            <i className="fa fa-list"></i> {isOpen && "Rapport pointage"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapport collect")}>
            <i className="fa fa-list"></i> {isOpen && "Rapport Collecte"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapport d'affectation")}>
            <i className="fa fa-list"></i> {isOpen && "Rapport d'affectation"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Tonnage")}>
            <i className="fa fa-list"></i> {isOpen && "Tonnage"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Vidage")}>
            <i className="fa fa-list"></i> {isOpen && "Vidage"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapport levée bac")}>
            <i className="fa fa-list"></i> {isOpen && "Rapport levée bac"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("entreprise")}>
            <i className="fa fa-user-circle"></i> {isOpen && "Entreprise"}
          </a>
        </li>

      </ul>
    </nav>
  </aside>
);

export default Sidebar;
