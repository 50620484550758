import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';

function App() {
  return (
    <Routes>
      {/* Route vers la page de login */}
      <Route path="/login" element={<Login />} />

      {/* Redirection de la page d'accueil vers /login */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/home" element={<Home />} />

      {/* Ajoutez d'autres routes ici si nécessaire */}
    </Routes>
  );
}

export default App;
