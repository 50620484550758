import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { api } from '../config/constant.js';

const Login = () => {
  const [messageEmailInvalide, setMessageEmailInvalide] = useState('');
  const [emailPassword, setEmailPassword] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [helpBlock, setHelpBlock] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
// eslint-disable-next-line
  const [listEntreprise, setListEntreprise] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem('localS')
    axios.get(api + '/entreprises/getAll')
      .then(res => setListEntreprise(res.data.data))
      .catch(error => console.error(error));
  }, []);

  const emitDataEmail = () => {
    setModalIsOpen(true);
  };

  const login = () => {
    const body = {
      name: username,
      password: password
    };

    fetch(api + '/entreprises/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
  })
  .then(response => response.json())
  .then(data => {
    if (data.authorisation) {
      localStorage.setItem('entrepriseId', data.data[0].id);
      localStorage.setItem('entrepriseName', data.data[0].name);
      localStorage.setItem('entrepriseType', data.data[0].type);
      window.location.href = '/home';
      //navigate('/home');
    } else {
      setHelpBlock("Votre mot de passe est incorrect !");
    }
  })
  .catch(error => console.error('Erreur:', error));


    /*axios.post(api + '/entreprises/login', body)
      .then(res => {
        console.log('ress', res)
        if (res.data.authorisation) {
          localStorage.setItem('entrepriseId', res.data.data[0].id);
          localStorage.setItem('entrepriseName', res.data.data[0].name);
          localStorage.setItem('entrepriseType', res.data.data[0].type);
          navigate('/home');
        } else {
          setHelpBlock("Votre mot de passe est incorrect !");
        }
      })
      .catch(error => console.error(error));*/
  };

  const resetField = () => {
    setModalIsOpen(false);
    setEmailPassword('');
  };

  const envoieMailPassword = () => {
    setLoading(true);

    axios.get(api + '/entreprises/envoieMailPassword/'+ emailPassword )
      .then(res => {
        setLoading(false);
        if (res.data.status === "emaillength") {
          setMessageEmailInvalide("L'email que vous avez inséré n'existe pas dans notre répertoire.");
        } else if (res.data.status === "OK") {
          resetField();
          toast.success(res.data.message, { position: 'bottom-right', autoClose: 6000 });
        }
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
     <div className="limiter" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
      {/* Fond vidéo YouTube */}
      <div className="background-video" style={{ position: 'absolute', top: '0', left: '0', right: '0', bottom: '0', zIndex: '-1' }}>
        <iframe
          title="background-video"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/xjZumoGmojY?autoplay=1&mute=1&loop=1&playlist=xjZumoGmojY"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      </div>

      {/* Contenu de la page */}
      <div className="row justify-content-center" style={{ marginTop: '40px' }}>
        <div >
        <div className="login-wrap p-4 p-md-5" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '10px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(10px)' }}>
           <div className="icon d-flex align-items-center justify-content-center" style={{  minHeight: '120px', display:'flex', alignItems:'center', justifyContent:'center' }}>
              <img src="http://www.stylegeneration.ma/images/produits/conteneur-poubelle-240l-101.jpg" alt="Logo" style={{ width: '100px', height: '100px' }} />
            </div>
            <h3 className="text-center mb-4" style={{ display:'flex', justifyContent:'center', color: '#4bc119', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: '30px' }}>APP COLLECT</h3>

            <div className="login-form validate-form">
              <div className="wrap-input100 validate-input" style={{ marginBottom: '20px' }}>
                <input
                  className="input100"
                  type="text"
                  placeholder="Nom d'utilisateur"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  style={{ padding: '15px', borderRadius: '10px', border: '1px solid #ccc', fontSize: '16px' }}
                />
              </div>

              <div className="wrap-input100 validate-input" style={{ marginBottom: '20px' }}>
                <input
                  className="input100"
                  type="password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  style={{ padding: '15px',  borderRadius: '10px', border: '1px solid #ccc', fontSize: '16px' }}
                />
              </div>

              {helpBlock && <p className="error" style={{  color: 'red', marginBottom: '15px', fontSize: '14px' }}>{helpBlock}</p>}

              <div className="container-login100-form-btn" style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop: '20px' }}>
                <button
                  className="login100-form-btn"
                  onClick={login}
                  style={{ cursor:'pointer', marginLeft:'20px', marginRight:'20px', padding: '15px 25px', backgroundColor: '#4bc119', borderRadius: '50px', color: 'white', border: 'none',  fontSize: '16px', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)', transition: '0.3s' }}
                  onMouseOver={(e) => e.target.style.backgroundColor = '#45a10e'}
                  onMouseOut={(e) => e.target.style.backgroundColor = '#4bc119'}
                >
                  Connexion
                </button>
              </div>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
                <div
                  onClick={emitDataEmail}
                  style={{ marginBottom:'20px',  cursor: 'pointer', fontSize: '16px', color: '#1da1f2', transition: 'color 0.3s' }}
                  onMouseOver={(e) => e.target.style.color = '#0c85d0'}
                  onMouseOut={(e) => e.target.style.color = '#1da1f2'}
                >
                  Mot de passe oublié ?
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal pour réinitialisation du mot de passe */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Fond assombri
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            padding: '30px',
            maxWidth: '300px',
            backgroundColor: '#ffffff',
            borderRadius: '15px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
            margin: 'auto',
            position: 'relative',
            left:'-2px',
           fontFamily: 'Arial, sans-serif',
          }
        }}
      >
        <h2 style={{ marginBottom: '20px', fontSize: '22px', color: '#333', textAlign: 'center' }}>Réinitialisation du mot de passe</h2>

        <input
          type="email"
          placeholder="Votre email"
          value={emailPassword}
          onChange={e => setEmailPassword(e.target.value)}
          style={{
            padding: '15px',
            marginBottom: '20px',
            borderRadius: '8px',
            border: '1px solid #ddd',
            width: '100%',
            fontSize: '16px',
            boxSizing: 'border-box',
          }}
        />

        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <ClipLoader size={30} color="#4bc119" />
          </div>
        ) : (
          <button
            onClick={envoieMailPassword}
            style={{
              backgroundColor: '#4bc119',
              color: 'white',
              padding: '15px 0',
              borderRadius: '50px',
              width: '100%',
              fontSize: '16px',
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#3da110')}
            onMouseLeave={(e) => (e.target.style.backgroundColor = '#4bc119')}
          >
            Envoyer
          </button>
        )}

        {messageEmailInvalide && (
          <p style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>{messageEmailInvalide}</p>
        )}

        <button
          onClick={resetField}
          style={{
            marginTop: '15px',
            backgroundColor: '#ccc',
            color: 'black',
            padding: '15px 0',
            borderRadius: '50px',
            width: '100%',
            fontSize: '16px',
            border: 'none',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#bbb')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#ccc')}
        >
          Annuler
        </button>
      </Modal>


      <ToastContainer />
    </div>
  );
};

export default Login;
