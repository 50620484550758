import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { api } from '../config/constant.js';
import DataTable from 'react-data-table-component';

export default function Driver({localS}) {
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [entreprises, setEntreprises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDriver, setCurrentDriver] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [entrepriseType, setentrepriseType] = useState(localS[2]);

 // const entrepriseType = localStorage.getItem("entrepriseType");

  useEffect(() => {
    fetchEntreprises();
    loadDrivers();
  }, []);

  const loadDrivers = () => {
    setIsLoading(true);
        const entrepriseId = localS[0]// JSON.parse( localStorage.getItem("entrepriseId"));
    const url = entrepriseType === "1"
      ? `${api}/anomalies/getAll`
      : `${api}/anomalies/getAllByEntreprise`;

    const options = entrepriseType === "1"
      ? { method: "GET" }
      : {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ entrepriseId })
        };

    fetch(url, options)
      .then(response => response.json())
      .then(data => {
        setDrivers(data.data);
        setFilteredDrivers(data.data); // Initialize filteredDrivers with all data
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching drivers:", error);
        setIsLoading(false);
      });
  };

  const fetchEntreprises = () => {
    axios.get(api + "/entreprises/getAll")
      .then(response => setEntreprises(response.data.data));
  };
const handleSearch = (e) => {
  const searchValue = e.target.value;
  setSearchText(searchValue);
  const filteredData = drivers.filter((driver) => {
    // Vérifie si chaque champ est défini avant d'appeler `toLowerCase`
    const firstName = driver.description ? driver.description.toLowerCase() : "";
    const lastName = driver.comment ? driver.comment.toLowerCase() : "";

    return (
      firstName.includes(searchValue.toLowerCase()) ||
      lastName.includes(searchValue.toLowerCase())
    );
  });
  setFilteredDrivers(filteredData);
};

  const handleAddOrUpdateDriver = () => {
    if (
      !currentDriver.description
    //  !currentDriver.comment ||
      //!currentDriver.entrepriseId
    ) {
      alert("Veuillez remplir tous les champs.");
      return;
    }

    if(!currentDriver.entrepriseId){
        currentDriver.entrepriseId = localS[0]
    }

    setIsLoading(true);
    const url = actionType === "add"
      ? api + "/anomalies/add"
      : api + "/anomalies/update";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(currentDriver)
    })
    .then(response => response.json())
    .then(() => {
      loadDrivers();
      resetForm();
    })
    .catch(error => {
      console.error("Error saving :", error);
      setIsLoading(false);
    });
  };
const handleDeleteDriver = (id) => {
  const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");

  if (confirmed) {
    setIsLoading(true);
    fetch(api + '/anomalies/delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id })
    })
    .then(response => response.json())
    .then(() => {
      loadDrivers();
    })
    .catch(error => {
      console.error("Error deleting driver:", error);
      setIsLoading(false);
    });
  }
};


  const resetForm = () => {
    setShowModal(false);
    setCurrentDriver({});
    setIsLoading(false);
  };

  const openModal = (driver = {}) => {
    setActionType(driver.id ? "update" : "add");
    setCurrentDriver(driver);
    setShowModal(true);
  };

  const oa = [
    { name: "ID", selector: row => row.id, sortable: true },
    { name: "Description", selector: row => row.description, sortable: true },
    { name: "Commentaire", selector: row => row.comment, sortable: true },
    {
      name: "Actions",
      cell: row => (
        <>
          <Button onClick={() => openModal(row)} variant="warning" size="sm"><i className="fa fa-edit"></i></Button>
          <Button onClick={() => handleDeleteDriver(row.id)} variant="danger" size="sm" className="ms-2"><i className="fa fa-trash"></i></Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
    const copiedColumns = [...oa]; // Copie de `oa`
  if(entrepriseType === '1')
  copiedColumns.splice(1, 0, {
    name: "Entreprise",
    selector: row => row?.entreprise?.name,
    sortable: true,
    wrap: true,
  });

  const columns = entrepriseType === "1" ? copiedColumns : oa;



  return (
    <div className="driver-page">
      <Button onClick={() => openModal()} variant="primary" className="mb-3">Ajout</Button>

      <input
        type="text"
        placeholder="Rechercher..."
        value={searchText}
        onChange={handleSearch}
        className="form-control mb-3"
      />

      <div className="data-table">
        {isLoading ? (
          <ClipLoader color={"#123abc"} loading={isLoading} size={50} />
        ) : (
          <DataTable
            columns={columns}
            data={filteredDrivers}  // Utilisation des données filtrées
            pagination
          />
        )}
      </div>

      <Modal show={showModal} onHide={resetForm}>
        <Modal.Header closeButton>
          <Modal.Title>{actionType === "add" ? "Ajout" : "Modifier"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Description"
            value={currentDriver.description || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, description: e.target.value })}
            className="form-control mb-3"
          />
          <input
            type="text"
            placeholder="Commentaire"
            value={currentDriver.comment || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, comment: e.target.value })}
            className="form-control mb-3"
          />
{ entrepriseType === "1" &&
          <select
            value={currentDriver.entrepriseId || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, entrepriseId: e.target.value })}
            className="form-control"
          >
            <option value="">Select Entreprise</option>
            {entreprises.map(entreprise => (
              <option key={entreprise.id} value={entreprise.id}>{entreprise.name}</option>
            ))}
          </select>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetForm}>Close</Button>
          <Button variant="primary" onClick={handleAddOrUpdateDriver}>
            {actionType === "add" ? "Ajouter" : "Modifier"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
