import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { api } from '../config/constant.js';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
const limit = 50;

export default function Driver({localS}) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDGXDZajbxnXvvbC7TobxGQHzjfUPPMuMs',
  });

  function dateDiffbycreatedAt(date1, date2){
    if(date1 == undefined || date1 == null)
      return '----';
    if (date2 == undefined || date2 == null)
      return '----';

    let d1 = new Date(date1);
    let d2 = new Date(date2);

    // @ts-ignore
    let difference = Math.abs(d1 - d2); //to get absolute value
    //calculate for each one
    let Days = Math.floor(difference / ( 1000 * 60 * 60 * 24 ));
    let Hours = Math.floor((difference / ( 1000 * 60 * 60 )) % 24);
    let Mins = Math.floor((difference / ( 1000 * 60 )) % 60);
    let Seconds = Math.floor((difference / ( 1000 )) % 60);
    return `${Hours.toString().padStart(2, '0')}:${Mins.toString().padStart(2, '0')}:${Seconds.toString().padStart(2, '0')}`;
  }

  // pdfMake.vfs = pdfFonts.pdfMake.vfs;
   const generatePdf = async ( listAnomalies_bac, type ) => {
    // Fonction pour construire les données du tableau
    const buildTableBody = (data, columns) => {
      const body = [];

      // Ajouter les en-têtes de colonne
      body.push(columns);

      // Ajouter les lignes de données
      data.forEach((element) => {
        const dataRow = [];
        columns.forEach((column) => {
          if (column === "Entreprise") {
            const entreprise =
              element.entrepriseId != null ? element.entreprise.name : "";
            dataRow.push(entreprise);
          }
          if (column === "Date") {
            const formattedDate = moment(element.createdAt).format(
              "YYYY-MM-DD HH:mm"
            );
            dataRow.push(formattedDate);
          }
          if (column === "Bom") {
            const bom = element.bomId != null ? element?.bom?.immatriculation :'----';
            dataRow.push(bom);
          }
          if (column === "Chauffeur") {
            const chauffeur = element.driverId != null ? element.driver.lastname + '' + element.driver.firstname :'';
            dataRow.push(chauffeur);
          }
          if (column === "Km Initial") {
            const kmi =
              element.Kilometrageinitial != null ? element.Kilometrageinitial : "";
            dataRow.push(kmi);
          }
          if (column === "Km finale") {
            const kmf =
              element.Kilometragefinale != null ? element.Kilometragefinale : "";
            dataRow.push(kmf);
          }
          if (column === "Km parcourue") {
            const kmparcourue = element.Kilometragefinal != null ? element.Kilometragefinal-element.Kilometrageinitial : 0;
            dataRow.push(kmparcourue);
          }
          if (column === "Commentaire") {
            const comment = element.comment != null ? element.comment : '';
            dataRow.push(comment);
          }


        });
        body.push(dataRow);
      });

      return body;
    };

    // Fonction pour créer une table
    const table = (data, columns) => ({
      table: {
        headerRows: 1,
        body: buildTableBody(data, columns),
      },
    });

    // Définir la structure du PDF
    const dd = {
      content: [
        { text: "Rapport pointage", style: "header" },
        table(listAnomalies_bac, [
          "Entreprise",
          "Date",
          "Bom",
          "Chauffeur",
          "Km initial",
          "Km finale",
          "Km parcourue",
          "Commentaire"
        ]),
      ],
    };

    // Générer le PDF en fonction du type (affiche, download, print)
    if (type === "affiche") {
      pdfMake.createPdf(dd).open();
    } else if (type === "download") {
      pdfMake.createPdf(dd).download();
    } else if (type === "print") {
      pdfMake.createPdf(dd).print();
    }
  };


  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [entreprises, setEntreprises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDriver, setCurrentDriver] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState({isOpen:false, lat:'', lng:''});
  const [actionType, setActionType] = useState("");
  const [searchText, setSearchText] = useState("");
  const transformImageUrl = (url) => `data:image/png;base64,${url}`;
  const mapElementRef = useRef(null);
    const openCartRef = useRef(null);
 const [selectedDates, setSelectedDates] = useState({ debut: '', Hdebut:'', Hfin:'', fin: '' });
  const [entrepriseType, setentrepriseType] = useState(localS[2]);

  //const entrepriseType = localStorage.getItem("entrepriseType");

  useEffect(() => {
    fetchEntreprises();
    loadDrivers();
  }, []);

  const loadDrivers = async () => {
    setIsLoading(true);
    try {
      const response = entrepriseType === "1"
        ? await axios.get(api +`/rapportj/getAllRapportJ/`+limit)
        : await axios.post(api + `/rapportj/getRapportJByEntreprise/` + limit, { entrepriseId: localS[0] });

      setDrivers(response.data.data);
      setFilteredDrivers(response.data.data);
    } catch (error) {
      console.error("Erreur lors du chargement des anomalies :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEntreprises = () => {
    axios.get(api + "/entreprises/getAll")
      .then(response => setEntreprises(response.data.data));
  };

const handleSearch = (e) => {
  const searchValue = e.target.value;
  setSearchText(searchValue);
  const filteredData = drivers.filter((driver) => {
    const entreprise = driver?.entrepriseId ? driver.entreprise.name.toLowerCase() : "";
    const date = driver?.createdAt ? driver?.createdAt.toLowerCase() : "";
    const bom = driver?.bomId ? driver?.bom?.immatriculation.toLowerCase() : "";
    const firstName = driver.firstname ? driver.firstname.toLowerCase() : "";
    const lastName = driver.lastname ? driver.lastname.toLowerCase() : "";
   const Kilometrageinitial = driver?.Kilometrageinitial ? driver?.Kilometrageinitial.toLowerCase() : "";
   const Kilometragefinale = driver?.Kilometragefinal ? driver?.Kilometragefinal.toLowerCase() : "";
    const comment = driver?.comment ? driver?.comment.toLowerCase() : "";


    return (
      entreprise.includes(searchValue.toLowerCase()) ||
      date.includes(searchValue.toLowerCase()) ||
      bom.includes(searchValue.toLowerCase()) ||
      Kilometrageinitial.includes(searchValue.toLowerCase()) ||
      Kilometragefinale.includes(searchValue.toLowerCase()) ||
      comment.includes(searchValue.toLowerCase()) ||
      lastName.includes(searchValue.toLowerCase()) ||
      firstName.includes(searchValue.toLowerCase())
    );
  });
  setFilteredDrivers(filteredData);
};



  const handleAddOrUpdateDriver = () => {
  /*  if (
      !currentDriver.firstname ||
      !currentDriver.lastname ||
      !currentDriver.immatricule ||
      !currentDriver.entrepriseId
    ) {
      alert("Veuillez remplir tous les champs.");
      return;
    }
    setIsLoading(true);
    const url = actionType === "add"
      ? api + "/drivers/add"
      : api + "/drivers/update";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(currentDriver)
    })
    .then(response => response.json())
    .then(() => {
      loadDrivers();
      resetForm();
    })
    .catch(error => {
      console.error("Error saving driver:", error);
      setIsLoading(false);
    });*/
  };

const handleDeleteDriver = async (id) => {
      const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");
  if (confirmed) {
      setIsLoading(true);
      setDrivers([]);
      setFilteredDrivers([]);
    try {
       await axios.post(api + `/rapportj/deleteRapportJ`, { id });
      loadDrivers();
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);
    } finally {
      setIsLoading(false);
    }}
};

  const deleteMultipleAnomalies = async () => {
   const selectedData = filteredDrivers.filter((item) => selectedItems.includes(item.id));
   const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");

  if (confirmed) {
      setIsLoading(true);
      setDrivers([]);
      setFilteredDrivers([]);
    try {
      await axios.post(api +`/rapportj/supprimerPlusieurs`, { arrayPointages: selectedItems });
      loadDrivers();
    } catch (error) {
      console.error("Erreur lors de la suppression multiple :", error);
    } finally {
      setIsLoading(false);
      setSelectedItems([]);
      loadDrivers()
    }}
  };

  const resetForm = () => {
   setShowModal1({isOpen:false });
   // setCurrentDriver({});
   // setIsLoading(false);
  };

  const openModal = (driver = {}) => {
    setActionType(driver.id ? "update" : "add");
    setCurrentDriver(driver);
    setShowModal(true);
  };

  const oa = [
    {
    name: "",
    cell: (row) => (
     <input
          type="checkbox"
          checked={selectedItems.includes(row.id)}
          onChange={(e) => handleCheckboxChange(row.id, e.target.checked)}
     />
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    width: "50px",
  },
  { name: "ID",
    minWidth: '70px',
    maxWidth: '70px',
    selector: row => row.id, sortable: true },
  {
    name: "Date",
    selector: (row) => moment(row.createdAt).format('yyyy-MM-DD HH:mm:ss'),
    sortable: true,
    wrap:true,
    minWidth: '160px',
    maxWidth: '170px',

  },
  {
    name: "Véhicule",
    selector: (row) => row?.bomId != null ? row?.bom?.immatriculation:'',
    sortable: true,
    minWidth: '100px',
    maxWidth: '150px',
    wrap:true
  },
  {
    name: "Chauffeur",
    selector: (row) => row.driverId != null ? row?.driver?.lastname+' '+row?.driver?.firstname:'',
    cell:(row) =>(<div  style={{
          position: 'relative',
          display: 'inline-block',
          cursor: 'pointer', // Indique que l'élément est survolable
        }}>{row.driverId != null ? row?.driver?.lastname+' '+row?.driver?.firstname:''}</div>),
    sortable: true,
  },
{
     name: "Km initial",
     minWidth: '90px',
    maxWidth: '90px',
    selector: (row) => row?.Kilometrageinitial != null ? row?.Kilometrageinitial:'',
    sortable: true,
  },
  {
     name: "Km finale",
     minWidth: '90px',
    maxWidth: '90px',
    selector: (row) => row?.Kilometragefinal != null ? row?.Kilometragefinal:'',
    sortable: true,
  },
   {
     name: "Km parcourue",
     minWidth: '90px',
    maxWidth: '90px',
    selector: (row) => row.Kilometragefinal != null ? row.Kilometragefinal-row.Kilometrageinitial : 0,
    sortable: true,
  },
  {
     name: "Commentaire",
     minWidth: '90px',
    maxWidth: '90px',
    selector: (row) => row?.comment != null ? row?.comment:'',
    sortable: true,
  },

    {
      name: "Actions",
      maxWidth: '100px',
      cell: row => (
        <>
          <Button onClick={() => handleDeleteDriver(row.id)} variant="danger" size="sm" className="ms-2"><i className="fa fa-trash"></i></Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

    const copiedColumns = [...oa]; // Copie de `oa`
  if(entrepriseType === '1')
  copiedColumns.splice(2, 0, {
    name: "Entreprise",
    selector: row => row?.entreprise?.name,
    sortable: true,
    wrap: true,
  });

  const columns = entrepriseType === "1" ? copiedColumns : oa;



   const openMap = (latitude, longitude) => {
    setShowModal1({isOpen:true, lat:parseFloat(latitude), lng: parseFloat(longitude)});
  };

 const [selectedItems, setSelectedItems] = useState([]);
  const handleCheckboxChange = (id, isChecked) => {
    setSelectedItems((prev) =>
      isChecked
        ? [...prev, id]
        : prev.filter((itemId) => itemId !== id)
    );
  };

const handleGenerateExcel = () => {
  let id_E = entrepriseType === "1" ? "" : localS[0] || "";
  let listAnomalies_bacExcel = [];

  if (filteredDrivers.length > 0) {
    setIsLoading(true);
    for (let i = 0 ; i < this.filteredDrivers.length; i++){
      const item = this.filteredDrivers[i];
      let transform0 = null;
      if(item.createdAt !== null && item.createdAt !== undefined ){
        item.createdAt = item.createdAt;
      }
      listAnomalies_bacExcel.push(item);
    }

    let body = {
      listPointageExcel:listAnomalies_bacExcel,
      id_E: id_E,
    };
    fetch(api + '/rapportj/excel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200 && data.url) {
          const anchor = document.createElement('a');
          anchor.href = data.url;
          //anchor.download = 'anomalies.xlsx';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        } else {
          console.error("Erreur : L'URL du fichier Excel est invalide.");
        }
      })
      .catch(error => console.error("Erreur lors de la génération du fichier Excel :", error))
      .finally(() => setIsLoading(false));
  }
};

   const handleDateChange = (e) => {
    setSelectedDates({ ...selectedDates, [e.target.name]: e.target.value });
  };

  function formatage_date(imput_date){
    let ladate = imput_date;
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1).toString().padStart(2,'0')+'-'+ladate.getDate().toString().padStart(2,'0')
  }

   function less_day_date(date, nbr){
    let dateStart = new Date(date);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() - nbr));
    return formatage_date(dateEnd);
  }

  function add_day_date(date, nbr){
    let dateStart = new Date(date);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() + nbr));
    return formatage_date(dateEnd);
  }
    function date_aujour_d_hui(){
    let ladate = new Date();
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1).toString().padStart(2,'0')+'-'+ladate.getDate().toString().padStart(2,'0')
  }
  function traitement_semaine(aujourdhui){
    let ladate = new Date();
    let position = ladate.getDay();
    let debut_semaine =  less_day_date(aujourdhui, position);
    let fin_semaine = add_day_date(debut_semaine,6);
    return {debut_semaine, fin_semaine}
  }

  function traitement_mois(aujourdui){
    let mois = aujourdui.slice(5,7);
    let annees = aujourdui.slice(0,4);
    let nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    let debut_mois = aujourdui.slice(0,8)+'01';
    let fin_mois = aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
    return {debut_mois, fin_mois};
  }
 function date_lendemain(){
    const today = new Date();
    const tomorrow = new Date();
    return tomorrow.setDate(today.getDate()-1);
  }
  useEffect(() => {

    const blob = async () => {
    const aujourdhui = date_aujour_d_hui();

    let dateStart = new Date(aujourdhui);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() - 1));
    const hier = formatage_date(dateEnd);

    const { debut_semaine, fin_semaine } = traitement_semaine(aujourdhui);
    const { debut_mois, fin_mois } = traitement_mois(aujourdhui);
    let basta= {...selectedDates, aujourdhui, hier, debut_semaine, fin_semaine, debut_mois, fin_mois };
    const entrepriseId = localS[0];
      if(entrepriseId === "12"){
        basta = {...basta, Hdebut : '00:00', Hfin : '23:59', debut: aujourdhui, fin : aujourdhui}
      }else if(entrepriseId === "10"){
        let debut= moment.utc(date_lendemain()).format("yyyy-MM-DD");
       basta = {...basta, Hdebut : '17:00', Hfin : '16:00', debut, fin : aujourdhui}
    }else{
      basta = {...basta, Hdebut : '01:00', Hfin : '23:59', debut: aujourdhui, fin : aujourdhui};
    }
    setSelectedDates(basta);
    }
    blob()
  }, []);

  async function handleFilterByDate(start, end){
    let start_ = start;
    let end_ = end;
    if(selectedDates.Hdebut !== '' || selectedDates.Hfin !== ''){
      start_ = start + " " + selectedDates.Hdebut + ":00";
      end_ = end + " " + selectedDates.Hfin + ":00";
    }

    setIsLoading(true);
    setTimeout(()=>setIsLoading(false),10000);
    const response = entrepriseType === "1"
        ? await axios.post(api + `/rapportj/betweenTwoDates`, {
          startDate: start_,
          endDate: end_
        })
        : await axios.post(api + `/rapportj/betweenTwoDatesByEntreprise`, {
          entrepriseId: localS[0],
          startDate: start_,
          endDate: end_
        });

    setDrivers(response.data.data);
    setFilteredDrivers(response.data.data);
  }


 if (!isLoaded) {
    return <div>Chargement de la carte...</div>;
  }

  return (
    <div  style={{ maxHeight: window.innerHeight - 100, overflowY: 'auto' }} className="driver-page">

        <div className="btn-periode" style={{ marginTop: 15 }} role="group" aria-label="Basic example">
        <button style={{marginRight:'5px'}} className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.aujourdhui, selectedDates.aujourdhui)}>
          Aujourd'hui
        </button>
        <button style={{marginRight:'5px'}}  className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.hier, selectedDates.hier)}>
          Hier
        </button>
        <button style={{marginRight:'5px'}}  className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.debut_semaine, selectedDates.fin_semaine)}>
          Semaine
        </button>
        <button style={{marginRight:'5px'}}  className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.debut_mois, selectedDates.fin_mois)}>
          M
        </button>

        <button style={{marginRight:'5px'}}  className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.debut, selectedDates.fin)}>
          Afficher
        </button>
        <select  style={{marginRight:'5px'}}
            value={""}
            onChange={(e) =>{
              const val = e.target.value;
              if(val === 'excel')
                handleGenerateExcel()
                else
                  generatePdf(filteredDrivers, "download" )
            }}
            className="btn btn-info btn-sm dropdown-toggle"
          >
            <option value="">Telecharger</option>
              <option className="dropdown-item" value="excel">Fichier Excel</option>
              <option className="dropdown-item" value="pdf">Fichier Pdf</option>
          </select>

        <input id="date" type="date" name="debut"
       // value={selectedDates.debut}
        onChange={handleDateChange} />
        <input id="time" type="time"
        //value={selectedDates.Hdebut}
        name="Hdebut" onChange={handleDateChange} />
        <input id="date1" type="date" name="fin"
       // value={selectedDates.fin}
        onChange={handleDateChange} />
        <input id="time1" type="time"
        //value={selectedDates.Hfin}
        name="Hfin" onChange={handleDateChange} />
      </div>

      <input
        type="text"
        placeholder="Rechercher..."
        value={searchText}
        onChange={handleSearch}
        className="form-control mb-3"
        style={{marginTop:'10px'}}
      />

      <div className="data-table">
        {isLoading ? (
          <ClipLoader color={"#123abc"} loading={isLoading} size={50} />
        ) : (
          <DataTable
            columns={columns}
            data={filteredDrivers}
            pagination
            style={{width: "100%"}}
          />
        )}
      </div>

      <Modal show={showModal1.isOpen} onHide={resetForm}>
        <Modal.Body>
          <GoogleMap
              //ref={mapElementRef}
              mapContainerStyle={{
                width: '100%',
                height: '400px',
              }}
              center={{
                lat: showModal1.lat,
                lng: showModal1.lng,
              }}
              zoom={12}
            >
              <Marker position={{ lat: showModal1.lat, lng: showModal1.lng }} />
            </GoogleMap>
        </Modal.Body>
      </Modal>
      <button className="btn btn-sm btn-danger" onClick={deleteMultipleAnomalies}>
          Supprimer les rapports cochés
        </button>

    </div>
  );
}
